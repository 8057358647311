import React from "react";
import "./About.css";
const About = () => {
  return (
    <div className="about_us">
      <div className="AboutContent">
        <h2>About Us</h2>
        <p>
        CodeChef ADGIPS & ECELL ADGIPS are dynamic communities of tech enthusiasts and entrepreneurship enthusiasts who are committed to promoting creativity and problem solving.

We are revolutionizers and with the power of our ideas intend to bring about a positive change in the world that would make it a better place for everyone to live in. Constantly developing and building ourselves is our motto.

Everyone can materialize what they fantasize about, it’s just a matter of opening one’s eyes and putting in the right amount of faith and hard work. Our shared goal is to inspire meaningful impact and innovation, creating a transformative space where technology meets empowerment
        </p>
      <button className='button-73'>
        <a href="https://forms.gle/6V9iG7T2Cdtsk8M1A">Register now</a>
      </button>
      </div>
    </div>
  );
};
export default About;
