import React from "react";
import "./About.css";
const About = () => {
  return (
    <div className="about_us">
      <div className="AboutContent">
        <h2>About NFT Rush</h2>
        <p>
        Get ready for an adrenaline-fueled adventure where teams will race against the clock to uncover hidden QR codes scattered throughout the campus. 🤩
Each QR code unlocks a unique NFT collectible.
        </p>
      <button className='button-73'>
        <a href="https://forms.gle/6V9iG7T2Cdtsk8M1A">Register now</a>
      </button>
      </div>
    </div>
  );
};
export default About;
