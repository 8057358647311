import React from "react";
import "./About.css";
const About = () => {
  return (
    <div className="about_us">
      <div className="AboutContent">
        <h2>About Us</h2>
        <p>
          WE AT ECELL-ADGIPS STRIVE TO INNOVATE, DEVELOP AND INSTILL AN
          ENTREPRENEURIAL SPIRIT IN OUR PEERS. WE ARE REVOLUTIONIZERS AND WITH THE
          POWER OF OUR IDEAS INTEND TO BRING ABOUT A POSITIVE CHANGE IN THE WORLD
          THAT WOULD MAKE IT A BETTER PLACE FOR EVERYONE TO LIVE IN. CONSTANTLY
          DEVELOPING AND BUILDING OURSELVES IS OUR MOTTO. EVERYONE CAN MATERIALIZE
          WHAT THEY FANTASIZE, IT’S JUST A MATTER OF OPENING ONE’S EYES AND
          PUTTING IN RIGHT AMOUNT OF FAITH AND HARD-WORK. WE BELIEVE EVERYONE HAS
          AN IMMENSE AMOUNT OF POTENTIAL, WE AT E-CELL AIM TO DIRECT THAT
          POTENTIAL AND USE IT IN SUCH A WAY THAT THE REWARDS IT WILL REAP WOULD
          BE BENEFICIAL FOR THE SOCIETY. A KEY TO A GOOD STARTUP IS A SUSTAINABLE
          BUSINESS MODEL AND THE BUILDING BLOCKS OF THAT STARTUP, ITS MEMBERS. WE
          WERE AWARDED THE BEST E-CELL SOCIETY IN DELHI BY DELHI COLLEGE AWARDS.
        </p>
      </div>

    </div>
  );
};
export default About;
