import React from "react";
import "./contact.css";
const Contact = () => {
  return (
    <div className="Contact">
      <h2>Contact Details</h2>
      <div className="contactDiv">
        <p>Mobile: </p>
        <p> ADARSH- 8527107183</p>
        <p> ANUSHKA- 9013412685</p>
      </div>

    </div>
  );
};
export default Contact;
