import React from "react";
import "./contact.css";
const Contact = () => {
  return (
    <div className="Contact">
      <h2>Contact Details</h2>
      <div className="contactDiv">
        <p>Mobile: </p>
        <p> JAIVAL-9667441895</p>
        <p> SANCHIT- 8860780401</p>
      </div>

    </div>
  );
};
export default Contact;
